




















































import {Component, Vue, Watch} from "vue-property-decorator"
import Options from "@/models/vue/Options"
import Company from "@/models/Company"
import CompanyService from "@/services/CompanyService"
import {getModule} from "vuex-module-decorators"
import LangModule from "@/store/LangModule"

@Component
export default class CompanyView extends Vue {
	lang: any = getModule(LangModule).lang
	companies: Company[] = []
	page: number = 1
	pageCount: number = 0
	itemsPerPage: number = 10
	loading: boolean = false
	search: string = ""
	headers = [
		{ text: "Xgest ID", value: "connectorId", width: "auto", align: "center" },
		{ text: this.lang.commercialName, value: "commercialName", width: "auto", align: "center" },
		{ text: this.lang.name, value: "name", width: "auto", align: "center" },
		{ text: this.lang.cif, value: "cif", width: "auto", align: "center" },
	]
	options: Options = new Options()
	totalItems: number = 0

	@Watch("search")
	watchSearch() {
		if (this.search.length > 2) this.watchOptions()
	}

	@Watch("options")
	watchOptions() {
		CompanyService.getCompanies(this, this.companies, this.page - 1, this.itemsPerPage, this.search)
	}

	rowClick(company: Company) {
		this.$router.push({path: "/companies/" + company.id})
	}
}
